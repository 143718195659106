<template>
  <div>
    <GmapMap
      :center="center.coordenada"
      :zoom="zoom"
      map-type-id="terrain"
      :style="dimensoes"
      ref="map"
      id="Gmap"
    >
      <GmapCircle
        v-if="gmapCircle"
        :key="'radius'"
        :center="center.coordenada"
        :radius="1000"
        :options="{
          strokeColor: '#0070c0',
          strokeOpacity: 0.5,
          strokeWeight: 2,
          fillColor: '#0070c0',
          fillOpacity: 0.2,
          zIndex: 0,
        }"
      />

      <GmapMarker
        :key="m.descricao + index"
        v-for="(m, index) in marcas"
        :position="m.coordenada"
        :clickable="true"
        :draggable="false"
        :icon="m.iconColor"
        :zIndex="getZIndex(m)"
        @click="showMarkInfoWindow(m)"
      />

      <GmapPolygon
        :key="'polygon' + index"
        v-for="(p, index) in poligonos"
        :paths="p.vertices"
        :options="p.options"
        @click="showInfoWindow(p, $event)"
      />

      <GmapInfoWindow
        v-for="(tooltip, index) in tooltips"
        :key="tooltip.text + index"
        :options="tooltip.options"
        :position="tooltip.position"
        :opened="tooltip.opened"
        @closeclick="closeInfoWindow(index)"
        class="custom-info-window"
      >
      </GmapInfoWindow>
    </GmapMap>
    <span class="material-symbols-outlined btn-center" @click="centralizarMapa">
      my_location
    </span>
  </div>
</template>

<script>
export default {
  props: {
    center: Object,

    marcas: {
      type: Array,
      required: true,
    },

    dimensoes: Object,
    zoom: Number,
    poligonos: Array,
    gmapCircle: Boolean,
  },
  data() {
    return {
      tooltips: [],
      centroInicial: {},
      map: {},
    };
  },
  methods: {
    getZIndex(marca) {
      if (
        marca.descricao?.includes("Matriz") ||
        marca.tipo?.includes("centro")
      ) {
        return 10;
      }
      if (marca.tipo?.includes("EAP")) {
        return 2;
      }
      return 1;
    },

    createMap() {
      const mapOptions = {
        center: { lat: -34.397, lng: 150.644 },
        zoom: 8,
      };
      this.map = new google.maps.Map(
        document.getElementById("map"),
        mapOptions
      );
    },

    showInfoWindow(poligono, event) {
      this.tooltips = [];

      if (poligono.tipo == "EDS") {
        if (poligono.distancia > 0) {
          var distanciaKm =
            "Distância do ponto central: " +
            poligono.distancia.toFixed(2) +
            "km";
        } else {
          var distanciaKm = "";
        }

        const latitude = event.latLng.lat();
        const longitude = event.latLng.lng();

        const tooltip = {
          position: { lat: latitude, lng: longitude },
          text: poligono.descricao + poligono.distancia,
          opened: true,
          options: {
            maxWidth: 500,
            pixelOffset: {
              width: 0,
              height: -5,
            },
            content:
              '<div class="custom-info-window" style="width: fit-content; min-width: 300px; padding: 0 10px; font-weight: 500">' +
              poligono.descricao +
              "<br/>" +
              "</div>" +
              '<div class="custom-info-window" style="width: fit-content; min-width: 300px; padding: 0 10px 10px 10px;">' +
              distanciaKm +
              "</div>",
          },
        };

        if (
          !this.tooltips.some(
            (existingTooltip) => existingTooltip.text === tooltip.text
          )
        ) {
          this.tooltips.push(tooltip);
        } else {
          const index = this.tooltips.findIndex(
            (existingTooltip) => existingTooltip.text === tooltip.text
          );
          if (index !== -1) {
            this.tooltips.splice(index, 1);
          }
          this.tooltips.push(tooltip);
        }
      } else if (poligono.tipo == "Inundações") {
        if (poligono.distancia > 0) {
          var distanciaKm =
            "Distância do ponto central: " +
            poligono.distancia.toFixed(2) +
            "km";
        } else {
          var distanciaKm = "";
        }
        if (poligono.area > 0) {
          var areaKm = "Área: " + poligono.area.toFixed(3) + "km²";
        } else {
          var areaKm = "";
        }

        const latitude = event.latLng.lat();
        const longitude = event.latLng.lng();

        const tooltip = {
          position: { lat: latitude, lng: longitude },
          text: poligono.classe_risco + poligono.distancia,
          opened: true,
          options: {
            maxWidth: 500,
            pixelOffset: {
              width: 0,
              height: -5,
            },
            content:
              '<div class="custom-info-window" style="width: fit-content; min-width: 300px; padding: 0 10px; font-weight: 500">' +
              "Classe do risco: " +
              poligono.classe_risco +
              "<br/>" +
              "</div>" +
              '<div class="custom-info-window" style="width: fit-content; min-width: 300px; padding: 0 10px;">' +
              distanciaKm +
              "</div>" +
              '<div class="custom-info-window" style="width: fit-content; min-width: 300px; padding: 0 10px 10px 10px;">' +
              areaKm +
              "</div>",
          },
        };

        if (
          !this.tooltips.some(
            (existingTooltip) => existingTooltip.text === tooltip.text
          )
        ) {
          this.tooltips.push(tooltip);
        } else {
          const index = this.tooltips.findIndex(
            (existingTooltip) => existingTooltip.text === tooltip.text
          );
          if (index !== -1) {
            this.tooltips.splice(index, 1);
          }
          this.tooltips.push(tooltip);
        }
      } else {
        const latitude = event.latLng.lat();
        const longitude = event.latLng.lng();

        let descricao = poligono.descricao;
        descricao = descricao
          .replace(/_/g, " ")
          .replace(/^\w/, (c) => c.toUpperCase());

        const tooltip = {
          position: { lat: latitude, lng: longitude },
          text: poligono.distancia,
          opened: true,
          options: {
            maxWidth: 500,
            pixelOffset: {
              width: 0,
              height: 0,
            },
            content:
              '<div class="custom-info-window" style="width: fit-content; min-width: 300px; padding: 0 10px 10px 10px; font-weight: 500">' +
              descricao +
              "</div>",
          },
        };

        this.tooltips.push(tooltip);
      }
    },

    showMarkInfoWindow(mark) {
      this.tooltips = [];

      if (mark.distancia > 0) {
        var distanciaKm =
          "Distância do ponto central: " + mark.distancia.toFixed(2) + "km";
      } else {
        var distanciaKm = "";
      }

      const tooltip = {
        position: mark.coordenada,
        text: mark.descricao + mark.distancia,
        opened: true,
        options: {
          maxWidth: 500,
          pixelOffset: {
            width: 0,
            height: -25,
          },
          content:
            '<div class="custom-info-window" style="width: fit-content; min-width: 300px; padding: 0 10px 0px 10px; font-weight: 500">' +
            mark.descricao +
            "</div>" +
            '<div class="custom-info-window" style="width: fit-content; min-width: 300px; padding: 0 10px 10px 10px;">' +
            distanciaKm +
            "</div>",
        },
      };
      if (
        !this.tooltips.some(
          (existingTooltip) => existingTooltip.text === tooltip.text
        )
      ) {
        this.tooltips.push(tooltip);
      }
    },

    centralizarMapa() {
      const mapInstance = this.$refs.map.$mapPromise;

      mapInstance.then((map) => {
        map.setCenter(this.centroInicial.coordenada);
        // map.setZoom(14);
      });
    },

    closeInfoWindow(index) {
      this.tooltips.splice(index, 1);
    },
  },

  mounted() {
    // window.app = this;
    this.centroInicial = this.center;
  },
};
</script>

<style scoped>
.btn-center {
  position: absolute;
  left: 185px;
  transform: translateY(-80px);
  z-index: 2;
  cursor: pointer;
  font-size: 30px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  padding: 5px;
  color: #666666;
  font-weight: bold;

  &:hover {
    color: black;
  }
}
</style>
<style>
.gm-style-iw-d {
  overflow: hidden !important;
  line-height: 18px;
  white-space: nowrap;
}
.gm-style-iw-chr {
  height: 15px;
}
</style>
