var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('GmapMap',{ref:"map",style:(_vm.dimensoes),attrs:{"center":_vm.center.coordenada,"zoom":_vm.zoom,"map-type-id":"terrain","id":"Gmap"}},[(_vm.gmapCircle)?_c('GmapCircle',{key:'radius',attrs:{"center":_vm.center.coordenada,"radius":1000,"options":{
        strokeColor: '#0070c0',
        strokeOpacity: 0.5,
        strokeWeight: 2,
        fillColor: '#0070c0',
        fillOpacity: 0.2,
        zIndex: 0,
      }}}):_vm._e(),_vm._l((_vm.marcas),function(m,index){return _c('GmapMarker',{key:m.descricao + index,attrs:{"position":m.coordenada,"clickable":true,"draggable":false,"icon":m.iconColor,"zIndex":_vm.getZIndex(m)},on:{"click":function($event){return _vm.showMarkInfoWindow(m)}}})}),_vm._l((_vm.poligonos),function(p,index){return _c('GmapPolygon',{key:'polygon' + index,attrs:{"paths":p.vertices,"options":p.options},on:{"click":function($event){return _vm.showInfoWindow(p, $event)}}})}),_vm._l((_vm.tooltips),function(tooltip,index){return _c('GmapInfoWindow',{key:tooltip.text + index,staticClass:"custom-info-window",attrs:{"options":tooltip.options,"position":tooltip.position,"opened":tooltip.opened},on:{"closeclick":function($event){return _vm.closeInfoWindow(index)}}})})],2),_c('span',{staticClass:"material-symbols-outlined btn-center",on:{"click":_vm.centralizarMapa}},[_vm._v(" my_location ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }